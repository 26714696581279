
import ScrollTo from './utils/scrollTo.js';
import getClosest from './utils/getClosest.js';

import flatpickr from "flatpickr";
import { english } from "flatpickr/dist/l10n/default.js"
import { German } from "flatpickr/dist/l10n/de.js"
import { Norwegian } from "flatpickr/dist/l10n/no.js"

import daterangepicker from "daterangepicker";
import moment from "moment";
import nb from "moment/locale/nb";
moment.locale('nb');


console.log(daterangepicker);

class GrotliApp {

    constructor() {

        console.log('Grotli');


        this.siteHeader = document.querySelector('.site-header');
        this.contentPusher = document.querySelector('.content-pusher');

        this.introImage = document.querySelector('.page-introduction--background-image');
        this.introVideo = document.querySelector('.page-introduction--background-video');

        this.prevScrollPosition = 0;

        this.setContentPusherHeight()
        this.init()

    }


    setContentPusherHeight() {

      this.contentPusher.style.height = (this.siteHeader.clientHeight-2)+'px';

    }


    handleScroll( event ) {

        let currentScrollPosition = window.pageYOffset;

        if( currentScrollPosition > this.siteHeader.clientHeight ) {
          
          if ( currentScrollPosition > this.prevScrollPosition ) {
            this.siteHeader.classList.add('condensed');
          } else {
            this.siteHeader.classList.remove('condensed');
          }
          

        } else {
          this.siteHeader.classList.remove('condensed');
        }

        this.prevScrollPosition = currentScrollPosition;

    }

    registerEvents() {

        // Open photoswipe gallery
        $('.open-gallery').on('click', function(){
            var list = $(this).next().children(), images = [];

            $.each( list, function(){

                var data = this.dataset;
                var obj = {
                    src: data.url,
                    msrc: data.url,
                    w: parseFloat(data.width),
                    h: parseFloat(data.height),
                    title: data.caption
                };
                images.push(obj);

            });
            console.log(images);

            var pswpElement = document.querySelectorAll('.pswp')[0];
            var options = {
                index: 0,
                closeOnScroll: false,
                history: false,
                zoomEl: false,
                shareEl: false,
                fullscreenEl: false,
            };
            var gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, images, options);
            gallery.init();

        });



        document.querySelector('.main-menu-toggle').addEventListener('click', function(event) {

            event.preventDefault();
            let header = document.querySelector('.site-header'),
                openClass = 'open-navigation';

            if ( header.classList.contains(openClass) ) {
                header.classList.remove(openClass);
            } else {
                header.classList.add(openClass);
            }

        });

        window.addEventListener('scroll', event => {
          this.handleScroll(event)
        });

        window.addEventListener('resize', () => {
          this.setContentPusherHeight();
        });


        // Menu click events
        let menuToggleButtons = document.querySelectorAll('button.indicator');
        console.log(menuToggleButtons);

        // nodelist to array
        var buttonArray = [];
        for(var i = menuToggleButtons.length; i--; buttonArray.unshift(menuToggleButtons[i]));

        buttonArray.map(button => {
          button.addEventListener('click', event => {

            let parent = getClosest(event.target, '.toplevel-item');
            if ( parent.classList.contains('open-child-navigation') ) {
              
              parent.classList.remove('open-child-navigation');

            } else {
              
              let openParent = document.querySelector('.toplevel-item.open-child-navigation');
              if ( openParent ) {
                openParent.classList.remove('open-child-navigation');
              }

              parent.classList.add('open-child-navigation');

            }

          })
        })



        let mainBookingForm = document.querySelector('form.the-booking-form');
        if ( mainBookingForm ) {
          mainBookingForm.addEventListener('submit', function(event) {
            
            event.preventDefault();

            var kvpairs = {};
            var form = event.target;
            for ( var i = 0; i < form.elements.length; i++ ) {
               var e = form.elements[i];
               kvpairs[e.name] = e.value;
            }

            let queryString = '';
            if (kvpairs.checkinoutdate) {
              
              let split = kvpairs.checkinoutdate.split(','),
                  start = moment(split[0], 'DD.MM.YYYY'),
                  end = moment(split[1], 'DD.MM.YYYY');

              queryString = '?source=external';
              queryString += '&fromYear='+start.format('YYYY');
              queryString += '&fromMonth='+start.format('MM');
              queryString += '&fromDay='+start.format('DD');
              queryString += '&toYear='+end.format('YYYY');
              queryString += '&toMonth='+end.format('MM');
              queryString += '&toDay='+end.format('DD');

            }

            window.location = 'https://booking.visbook.com/no/4495'+queryString;


          });
        }
        

        // 'https://booking.visbook.com/en/862?source=external&fromYear=2018&fromMonth=06&fromDay=28&toYear=2018&toMonth=06&toDay=29#11022'


    }

    fetchWeatherData() {

      $.ajax({
          method: 'GET',
          url: 'http://grotli-no.dev2/actions/yr-weather-forecast/forecast/fetch-forecast',

          cache: true,
          success: response => {
             console.log(response);
           }
      })

    }


    initDateTimePickers() {
    
      let lang = document.documentElement.getAttribute('lang'),
          selectedLang = null;

      switch ( lang ) {
        case 'nb' :
          selectedLang = Norwegian;
          break;
        case 'de' :
          selectedLang = German;
          break;
        default :
          selectedLang = english;
      }

      let no = {
        "format": "DD.MM.YYYY",
        "separator": " - ",
        "applyLabel": "Ok",
        "cancelLabel": "Avbryt",
        "fromLabel": "Fra",
        "toLabel": "Til",
        "customRangeLabel": "Custom",
        "weekLabel": "W",
        "daysOfWeek": [
          "Søn",
          "Man",
          "Tir",
          "Ons",
          "Tor",
          "Fre",
          "Lør"
        ],
        "monthNames": [
          "Januar",
          "Februar",
          "Mars",
          "April",
          "Mai",
          "Juni",
          "Juli",
          "August",
          "September",
          "Oktober",
          "November",
          "Desember"
        ],
        "firstDay": 1
      };


      let hiddenInput = document.querySelector('input[name="checkinoutdate"]'),
          UiElement = document.querySelector('div.checkinout-date');

      let datepicker = new daterangepicker( UiElement, {
        minDate: moment(),
        maxDate: moment().add(18, 'months'),
        showDropdowns: false,
        startDate: moment(),
        endDate: moment().add(1, 'days'),
        opens: 'center',
        locale: no
      }, ( start, end, label ) => {
        console.log(start, end, label);
        let startdate = start.format('DD. MMMM');
        let enddate = end.format('DD. MMMM');
        UiElement.innerHTML = startdate+' til '+enddate;
        hiddenInput.value = start.format('DD.MM.YYYY')+','+end.format('DD.MM.YYYY')
      });



    }


    init() {
      /*this.initPhotoSwipe();*/
      this.registerEvents();
        /*this.fetchWeatherData();*/
      this.initDateTimePickers();

    }

}


const app = new GrotliApp();